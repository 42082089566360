<template>
  <section class="news-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <validation-observer ref="observer" class="w-100">
      <div class="w-100">
        <b-form-group :label="$t('message.Photo')">
          <div v-if="form.photo" class="my-1">
            <img v-if="typeof form.photo === 'string'" :src="`${$backendHost}${form.photo}`" width="150" alt="">
          </div>
          <input type="file" @change="getNewsPhoto">
        </b-form-group>
      </div>
      <b-tabs content-class="pt-1" fill class="mt-2">
        <b-tab title="Uz">
          <div class="w-100">
            <b-form-group :label="$t('message.TitleUz')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.TitleUz')"
                  rules="required"
              >
                <b-input v-model="form.title_uz" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-form-group :label="$t('message.DescriptionUz')">
              <b-form-textarea
                  size="lg"
                  v-model="form.description_uz"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab title="Ru">
          <div class="w-100">
            <b-form-group :label="$t('message.TitleRu')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.TitleRu')"
                  rules="required"
              >
                <b-input v-model="form.title_ru" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-form-group :label="$t('message.DescriptionRu')">
              <b-form-textarea
                  size="lg"
                  v-model="form.description_ru"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab title="En">
          <div class="w-100">
            <b-form-group :label="$t('message.TitleEn')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.TitleEn')"
                  rules="required"
              >
                <b-input v-model="form.title_en" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-form-group :label="$t('message.DescriptionEn')">
              <b-form-textarea
                  size="lg"
                  v-model="form.description_en"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="success" @click="saveSubmit" >{{$t('message.Add')}}</b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { storeContent, updateContent, showContent } from '@/api/nomination'
import { formTemplate } from '@/utils/mixins/formTemplate';
import { quillEditor }  from 'vue-quill-editor';
import vSelect from 'vue-select';

function initForm(data) {
  const get = (key, value = null) => _.get(data,key,value);

  return {
    id: get('id', null),
    photo: get('photo', null),
    title_uz: get('title_uz', null),
    title_ru: get('title_ru', null),
    title_en: get('title_en', null),
    description_uz: get('description_uz', null),
    description_ru: get('description_ru', null),
    description_en: get('description_en', null),
  }
}

const actions = {
  add:storeContent,
  update: updateContent,
  show: showContent,
}

export default {
  name: 'NominationContentForm',
  components:{
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  mixins: [
    formTemplate,
  ],
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      experiences: [],

    }
  },
  methods: {
    reformatData() {
      this.form = {...this.form, ...{nomination_id: this.$route.params.id}};
      let form = this.prepareFormData(this.form);
      return form;
    },
    setForm(data) {
      this.form = initForm(data);
    },
    getNewsPhoto(e) {
      this.form.photo = e.target.files[0];
    }
  }
}
</script>

<style scoped>

</style>
